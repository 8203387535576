<template>
  <v-card>
    <v-card-title>
      {{ "Batteries" }}
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      <v-spacer></v-spacer>
      <v-btn class="ma-2" outlined color="indigo" @click="add()">
        {{ "Add New" }}
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="browse"
      :search="search"
      :loading="loading"
      loading-text="LOADING.... PLEASE WAIT"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.index="{ item }">
        {{ item.index }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-btn depressed text color="primary" @click="read(item)">
          <v-icon left dark>
            {{ icons.mdiPencil }}
          </v-icon>
        </v-btn>
        <v-btn depressed text color="error" @click="trash(item.id)">
          <v-icon left dark>
            {{ icons.mdiDelete }}
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="addBattery" max-width="720px">
      <v-card class="pa-15">
        <battery-add @updateBrownse="updateBrownse"></battery-add>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editBattery" max-width="720px">
      <v-card class="pa-15">
        <battery-read :batteries="batteries" @updateBrownse="updateBrownse"></battery-read>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mdiPencil, mdiDelete } from "@mdi/js";
import permission from "@/directive/permission"; // Permission directive (v-permission)
import Request from "@/core/api/request";
import BatteryAdd from "./BatteryAdd.vue";
import BatteryRead from "./BatteryRead.vue";

export default {
  name: "BatteryBrowse",
  directives: { permission },
  components: { BatteryAdd, BatteryRead },
  data() {
    return {
      loading: true,
      search: "",
      browse: [],
      batteries: {},
      headers: [
        { text: "NO.", value: "index", sortable: true },
        { text: "Asset Number", value: "asset_number", sortable: false },
        { text: "Serial Number", value: "serial_number", sortable: false },
        { text: "Entry Cycles", value: "entry_cycles", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Compatible Models", value: "compatible_models", sortable: false },
        { text: "Action.", value: "actions", sortable: false },
      ],
      addBattery: false,
      editBattery: false,
      icons: {
        mdiPencil,
        mdiDelete,
      },
    };
  },

  computed: {
    perms() {
      return {
        read: this.$IYA.ACL.PERMISSION_USER_READ,
        add: this.$IYA.ACL.PERMISSION_USER_ADD,
        remove: this.$IYA.ACL.PERMISSION_USER_REMOVE,
      };
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    updateBrownse() {
      this.initialize();
    },
    async initialize() {
      const { data, status } = await Request.get(`${this.$IYA.URI.BATTERIES__BROWSE}`);
      if (status !== this.$IYA.HTTP.OK) {
        this.loading = false;

        return;
      }
      if (data.data.length < 1) {
        this.loading = false;

        return;
      }
      data.data.forEach((item, i) => {
        item.index = i + 1;
      });
      this.browse = data.data;
      this.loading = false;
    },

    read(param) {
      this.batteries = param;
      this.editBattery = true;
    },

    add() {
      this.addBattery = true;
    },

    trash(id) {
      this.$swal({
        title: `Are you sure?`,
        text: `You can't revert your action`,
        type: `warning`,
        showCancelButton: true,
        confirmButtonText: `Yes Delete it!`,
        cancelButtonText: `No, Keep it!`,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(result => {
        if (result.value) {
          this.destroy(id);
        } else {
          this.$swal(`Cancelled`, `Your records are still intact`, `info`);
        }
      });
    },

    async destroy(id) {
      await Request.destroy(`${this.$IYA.URI.BATTERIES__DELETE}`, { id: id }).then(response => {
        if (response.data.success) {
          this.$swal(`Deleted`, `You successfully deleted this record`, `success`);
          this.initialize();
        } else {
          this.$swal(`Failed !`, `Failed to remove record`, `error`);
        }
      });
    },
  },
};
</script>
